export default [
  {
    header: 'Dashboards',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Admin',
        route: 'dashboard-admin',
        icon: 'ActivityIcon',
      },
      
    ],
  },
]
