export default [
  {
    header: 'Apps',
    icon: 'PackageIcon',
    children: [
      {
        title: 'Jobs',
        route: 'apps-jobs',
        icon: 'MessageSquareIcon',
      },
      /*{
        title: 'Candidates',
        icon: 'UserIcon',
        route: 'apps-candidates-list-all'
      },*/
      {
        title: 'Todo',
        route: 'apps-todo',
        icon: 'CheckSquareIcon',
      },
      {
        title: 'Calendar',
        route: 'apps-calendar',
        icon: 'CalendarIcon',
      },
      {
        title: 'Policy Documents',
        route: 'policy-documents',
        icon: 'BookOpenIcon',
      },
      // {
      //   title: 'Recruiter Work Tracker',
      //   route: 'dashboard-tracker',
      //   icon: 'ActivityIcon',
      // },
      // {
      //   title: 'Sourcer Work Tracker',
      //   route: 'sourcer-tracker',
      //   icon: 'ActivityIcon',
      // },
    ],
  },
]
